<template>
	
	<el-container style="height: 100%;">
			<!-- 左侧 -->
		<el-aside :width="fold?'auto':'170px'">
			
			<!-- 深蓝色背景  #00161d  color #fff -->
			<el-menu 
				class="main_menu" 
				@select="select" 
				:collapse="fold" 
				:unique-opened="true" 
				:collapse-transition="false" 
				:default-active="currentMenu"
			>
				
				<!-- 左侧菜单 -->
				<el-submenu v-for="(group,idx) in menu" :key="idx" :index="idx.toString()" >
					
					<!-- 菜单组 -->
					<template slot="title">
						<i class="icon" :class="group.icon?group.icon:'el-icon-menu'"></i>
						&nbsp;&nbsp;
						<span slot="title">{{group.name}}</span>
					</template>

					<!-- 单个菜单 -->
					<el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item>

				</el-submenu>

			</el-menu>
		</el-aside>
		<!-- 右边 -->
		<el-container class="right_container">

			<!-- 头部一行 -->
			<el-header class="right_header" style="height:50px">
				<div class="left" @click="menu_toggle">
					<i :class="'text-xxl '+(fold?'el-icon-s-unfold':'el-icon-s-fold')"></i>
				</div>
				<div class="right">
					<div class="text">{{main_user_company_name}} &nbsp;&nbsp;&nbsp; {{main_user_name}}</div>
					<div class="el-icon-switch-button logout_icon" @click="logout" ></div>
				</div>
			</el-header>

			<!-- 内容区域 -->
			<div class="container">
				<router-view></router-view>
			</div>
		</el-container>
	</el-container>
</template>
<script>
	import {mapMutations,mapState} from 'vuex'
	export default {
		data() {
			return {

				//菜单是否折叠
				fold: false,

				//选中菜单
				currentMenu: '',
				
				//主用户公司名称
				main_user_company_name:'',
				
				//主用户姓名
				main_user_name:'',
				
				//这些子账户登录时隐藏支付记录
				//not_pay_tels:['18672692607','13377857911','18696415263','15171431533','15971456412','18162681383','18819339971','18855370372'],
				
				//not_type_tels:['添加线路','运单列表','线路列表','用户信息'],
				
				//重要:这里需要解决 两种子账号(全数据/部分数据) 不同显示问题 2021-4-30
				//payment_tels:['15000567386','15221699967','13994973588','18634811121','15927570113','19834537779','13103995911','13109335923','17539170908','15881049943','18061271675','13913380517','18651651787','15189818250','18912934202','13678939261','15622779608','17728181041','15622779608','13678939261','18022401072','13925040186',],
				//只保留运单列表界面
				//sub_user_permission:['18157410260','15355163681'],
				//菜单数据
				menu: [{
						name:'个人中心',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'用户信息',
								url:'sub_user/user_info'
							},
						]
					},
					{
						name:'注册信息',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'货车列表',
								url:'truck_list'
							},
							{
								name:'司机列表',
								url:'driver_list'
							},
						]
					},
					{
						name:'线路管理',
						icon:'el-icon-s-goods',
						children:[{
								name:'添加线路',
								url:'business_line/bl_creat'
							},{
								name:'线路列表',
								url:'business_line/bl_list'
							}
						]
					},{
						name:'货源管理',
						icon:'el-icon-s-goods',
						children:[{
								name:'发布货源',
								url:'cargo/cargo_creat'
							},{
								name:'货源列表',
								url:'cargo/cargo_list'
							}
						]
					},{
						name:'运单管理',
						icon:'el-icon-s-order',
						children:[
						]
					},
				],
			}
		},
		computed:{
			...mapState(['main_user_info','user_info'])
		},
		mounted() {
			//页面初始化
			this.init()
			//默认选中
			//this.openDefault()

			//折叠主账号菜单
			this.set_main_aside_fold(true)
		},
		methods: {

			...mapMutations(['end_of_sub_user_logout','set_main_aside_fold']),
			//菜单展开折叠
			menu_toggle() {
				this.fold = this.fold ? false : true

				if(this.fold){
					localStorage.setItem('_pages_index_fold',1);
				}else{
					localStorage.setItem('_pages_index_fold',2);
				}
			},
			
			//菜单跳转
			select(index,indexPath) {
				
				var idx = indexPath[1].split("-")
				var menu = this.menu[idx[0]].children[idx[1]]
				var path = "/pages/sub_user/main_user_sys/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
			},

			//菜单跳转 上海杨腾
			select1(index,indexPath) {
				
				var idx = indexPath[1].split("-")
				var menu = this.menu1[idx[0]].children[idx[1]]
				var path = "/pages/sub_user/main_user_sys/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
			},
			select2(index,indexPath) {
				
				var idx = indexPath[1].split("-")
				var menu = this.menu2[idx[0]].children[idx[1]]
				var path = "/pages/sub_user/main_user_sys/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
			},
			//退出登录
			logout(){
				this.$my.other.confirm({
					content:'退出主账户登录,请确认?',
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_sub_user',
								ctr:'sub_user_logout_from_main_user',
							},
							callback:(data)=>{

								//删除缓存
								this.end_of_sub_user_logout();

								//跳转回列表页
								this.$router.push('/pages/sub_user/my_main_user_list');
							}
						});
					}
				});
			},
			
			//初始化
			init(){
				
				//取出数据
				let main_user_info=this.main_user_info
				
				//置入用户名和公司名
				this.main_user_company_name=main_user_info.main_user_company_name
				this.main_user_name=main_user_info.main_user_name

				//读取菜单权限
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'is_show_my_child',
					},
					callback:(data)=>{
						let obj = data.pc.sub_user
						let children = []
						Object.entries(obj).forEach((item,index)=>{
							if(item[0]=='tord_list'&& item[1]==1){
									children.push({
										name: "运单列表",
										url: "tord/tord_list"
									})
								}
							if(item[0]=='tord_deal'&& item[1]==1){
									children.push({
										name: "待接单",
										url: "tord/tord_deal"
									})
								}
							if(item[0]=='tord_act'&& item[1]==1){
								children.push({
									name: "运输中",
									url: "tord/tord_act"
								})
							}
							if(item[0]=='tord_end'&&item[1]==1){
									children.push({
										name: "已结束",
										url: "tord/tord_end"
									})
								}
							if(item[0]=='tord_wait_list_plan'&& item[1]==1){
									children.push({
										name: "支付计划",
										url: "tord/tord_wait_list_plan"
									})
								}
							if(item[0]=='tord_invoice'&& item[1]==1){
									children.push({
										name: "开票记录",
										url: "tord/tord_invoice"
									})
								}
							if(item[0]=='tord_invoice_end'&& item[1]==1){
									children.push({
										name: "可开票",
										url: "tord/tord_invoice_end"
									})
								}
							if(item[0]=='tord_pay_list'&& item[1]==1){
									children.push({
										name: "支付记录",
										url: "tord/tord_pay_list"
									})
								}
							if(item[0]=='tord_batch_edit'&& item[1]==1){
									children.push({
										name: "批量修改运费",
										url: "tord_real_time/tord_batch_edit"
									})
								}
							if(item[0]=='batch_tord_edit'&& item[1]==1){
									children.push({
										name: "批量修改运单",
										url: "tord_real_time/batch_tord_edit"
									})
								}
						})
						// for(let key in obj){
						// 	if(key=='tord_list'&& obj[key]==1){
							
						// 		children.push({
						// 			name: "运单列表",
						// 			url: "tord/tord_list"
						// 		})
						// 	}
						// if(key=='tord_deal'&& obj[key]==1){
						// 		children.push({
						// 			name: "待接单",
						// 			url: "tord/tord_deal"
						// 		})
						// 	}
						// if(key=='tord_act'&& obj[key]==1){
						// 	children.push({
						// 		name: "运输中",
						// 		url: "tord/tord_act"
						// 	})
						// }
						// if(key=='tord_end'&& obj[key]==1){
						// 		children.push({
						// 			name: "已结束",
						// 			url: "tord/tord_end"
						// 		})
						// 	}
						// if(key=='tord_wait_list_plan'&& obj[key]==1){
						// 		children.push({
						// 			name: "支付计划",
						// 			url: "tord/tord_wait_list_plan"
						// 		})
						// 	}
						// if(key=='tord_invoice'&& obj[key]==1){
						// 		children.push({
						// 			name: "开票记录",
						// 			url: "tord/tord_invoice"
						// 		})
						// 	}
						// if(key=='tord_invoice_end'&& obj[key]==1){
						// 		children.push({
						// 			name: "可开票",
						// 			url: "tord/tord_invoice_end"
						// 		})
						// 	}
						// if(key=='tord_pay_list'&& obj[key]==1){
						// 		children.push({
						// 			name: "支付记录",
						// 			url: "tord/tord_pay_list"
						// 		})
						// 	}
						// if(key=='tord_batch_edit'&& obj[key]==1){
						// 		children.push({
						// 			name: "批量修改运费",
						// 			url: "tord_real_time/tord_batch_edit"
						// 		})
						// 	}
						// if(key=='batch_tord_edit'&& obj[key]==1){
						// 		children.push({
						// 			name: "批量修改运单",
						// 			url: "tord_real_time/batch_tord_edit"
						// 		})
						// 	}
							
						// }
						console.log(children)
						this.menu[4].children = children
					}
				});
			},

			//展开并选中当前路由菜单
			openDefault() {
				var menu = this.menu
				for (var i in menu) {
					for (var j in menu[i].children) {
						var path = menu[i].children[j].url.replace("#", "")
						if (path == this.$route.path) {
							this.currentMenu = `${i}-${j}` 
						}
					}
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.logo {
		text-align: center;
		padding: 15px 0 0 0;
		img {
			width: 60px;
			padding: 10px 0px;
		}
	}

	.main_menu {
		height: 100%;
		overflow-x: hidden;
	}

	.right_container{
		padding: 0;

		.right_header{
			padding: 0;
			height: 50px;
			line-height: 50px;
			display: flex;
			justify-content: space-between;
			background-color: #fff;

			.left{
				cursor: pointer;
				color: #666;
				width: 50px;
				text-align: center;
			}
			.right{
				color: #666;
				display: flex;
				padding-right: 15px;
				.logout_icon{
					cursor: pointer;
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					font-weight:600;
				}
			}
		}

		.container{
			background-color:#aaa;
			padding: 5px;
			overflow-y: auto;
			border: 5px solid #fff;
			min-height: calc(100% - 75px);
			border-width: 0 15px 15px 0;
		}
	}
	
</style>